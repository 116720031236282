import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import env from 'helpers/env'
import axios from 'axios'
import { useForm, FormProvider } from 'react-hook-form'

import NarrowContainer from 'containers/NarrowContainer'

import Button from 'components/Button'
import FormattedMessage from 'components/FormattedMessage'
import Hint from 'components/Hint'
import FormItem from 'components/Form/Item'
import Input from 'components/Form/Input'
import PasswordInput from 'components/Form/Input/Password'
import Checkbox from 'components/Form/Checkbox'

import './index.less'

const IGNORED_REDIRECT_PATHS = ['/', '/login']

const Login = (props) => {
  const {
    redirectTo,
    currentUser,
  } = props

  const form = useForm({ mode: 'onChange' })
  const formRef = useRef()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState()
  const isWhiteLabelDomain = currentUser.isWhiteLabelDomain

  const submitHandler = (values) => {
    setIsSubmitting(true)
    setError(null)

    axios.post('/login.json', {
      user: {
        email: values.email,
        password: values.password,
        remember_me: values.remember_me,
      },
    })
      .then((result) => {
        let redirectTarget = result.headers.location

        if (redirectTo && !IGNORED_REDIRECT_PATHS.includes(redirectTo)) {
          redirectTarget = redirectTo
        }

        window.location.href = redirectTarget
      })
      .catch((error) => {
        setIsSubmitting(false)
        setError(error.response.data.error)
      })
  }

  // hacky: re-set autofilled values
  useEffect(() => {
    if (!formRef.current) { return }

    const [emailInput, passwordInput] = formRef.current.querySelectorAll('input')

    form.setValue('email', emailInput.value)
    form.setValue('password', passwordInput.value)
  }, [])

  return (
    <NarrowContainer className="users users--login simple-form-layout">
      <h1 className="simple-form-layout__title">
        <FormattedMessage id="users.login.title" />
      </h1>

      {error && (
        <Hint
          variant="error"
          modifiers={['bold']}
          className="simple-form-layout__hint"
        >
          {error}
        </Hint>
      )}

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)} ref={formRef}>
          <FormItem
            name="email"
            label={<FormattedMessage id="users.login.email" />}
            className="simple-form-layout__item"
          >
            <Input
              name="email"
              rules={{ required: <FormattedMessage id="errors.empty" /> }}
              className="simple-form-layout__item__input"
            />
          </FormItem>

          <FormItem
            name="password"
            label={<FormattedMessage id="users.login.password" />}
            className="simple-form-layout__item"
          >
            <PasswordInput
              name="password"
              rules={{ required: <FormattedMessage id="errors.empty" /> }}
              className="simple-form-layout__item__input"
            />
          </FormItem>

          <FormItem className="simple-form-layout__item">
            <Row>
              <Col span={12}>
                <Checkbox
                  name="remember_me"
                  className="users__form__item__checkbox simple-form-layout__item__checkbox"
                >
                  <FormattedMessage id="users.login.remember" />
                </Checkbox>
              </Col>

              <Col span={12} className="users__form__forget-password">
                <a href="/password/new" className="link link--gray">
                  <FormattedMessage id="users.login.forgetPassword" />
                </a>
              </Col>
            </Row>
          </FormItem>

          <FormItem className="simple-form-layout__item simple-form-layout__item--submit">
            <Button
              translationKey="users.login.submit"
              type="primary"
              size="large"
              htmlType="submit"
              className="ant-btn--with-border-radius-large ant-btn--brand-font"
              disabled={isSubmitting}
            />
          </FormItem>

          {!isWhiteLabelDomain && (
            <div className="users__form__login-with-sso">
              <a href={env.SSO_FORM_LINK} className="link">
                <FormattedMessage id="users.login.loginWithSso" />
              </a>
            </div>
          )}
        </form>
      </FormProvider>
    </NarrowContainer>
  )
}

Login.propTypes = {
  redirectTo: PropTypes.string,
  currentUser: PropTypes.shape({
    isWhiteLabelDomain: PropTypes.bool.isRequired,
  }).isRequired,
}

export default Login
