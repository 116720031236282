import React from 'react'
import classNames from 'classnames'

import './NarrowContainer.less'

const NarrowContainer: React.FC<NarrowContainerProps> = ({ className, children }) => (
  <div className={classNames('narrow-container', className)}>
    {children}
  </div>
)

interface NarrowContainerProps {
  className?: string
}

export default NarrowContainer
