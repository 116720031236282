import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as AntdCheckbox } from 'antd'
import classNames from 'classnames'
import { useController } from 'react-hook-form'

import './index.less'

const Checkbox = (props) => {
  const {
    name,
    className,
    defaultValue,
    checkedValue,
    children,
    rules,
    ...restProps
  } = props

  const {
    field: {
      onChange,
      value,
      ref,
      onBlur,
    },
    fieldState: { invalid },
  } = useController({
    name,
    rules,
    defaultValue,
  })

  return (
    <AntdCheckbox
      {...restProps}
      ref={ref}
      onBlur={onBlur}
      onChange={(checked) => onChange(checked.target.checked ? checkedValue || checked : checked)}
      checked={!!value}
      className={classNames(className, 'form__checkbox', {
        'form__checkbox--invalid': invalid,
      })}
    >
      {children}
    </AntdCheckbox>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  checkedValue: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  rules: PropTypes.object,
}

export default Checkbox
