import React, { useState } from 'react'

import Icon from 'components/Icon'
import Input from '../'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const PasswordInput: React.FC<PasswordInputProps> = (props) => {
  const {
    showPassword = false,
    className,
    ...restProps
  } = props

  const [isPasswordShown, togglePassword] = useState(showPassword)

  const onIconClick = () => {
    togglePassword(!isPasswordShown)
  }

  return (
    <Input
      {...restProps}
      className={classNames(styles, 'password-input', className)}
      type={isPasswordShown ? 'text' : 'password'}
      suffix={<Icon name={isPasswordShown ? 'eye-slash' : 'eye'} onClick={onIconClick} />}
    />
  )
}

interface PasswordInputProps extends Omit<React.ComponentProps<typeof Input>, 'type' | 'suffix'> {
  showPassword?: boolean
}

export default PasswordInput
